import React from 'react'

import { Layout } from '../old-components'

const WorkshopPage = () => (
  <Layout>
    <h1>Oh dear...</h1>
    <p>We can't seem to find the page you are looking for.</p>
  </Layout>
)

export default WorkshopPage
